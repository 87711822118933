#cookie-notice {
    display: block;
    visibility: hidden;
    position: fixed;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 25px 60px 20px;
    border-radius: 12px;
    height: auto;
    z-index: 100000;
    bottom: 3vh;
    left: 3vh;
    color: #707070!important;
    background: #ffffff!important;
    box-shadow: 3px 4px 30px rgba(0,0,0,.18);

    @include media-breakpoint-down(sm) {
        padding: 25px 20px 30px;
        width: calc(100% - 20px);
        box-shadow: 0 0 0 9999px hsla(227, 72%, 18%, 0.5);
        background: #fff!important;
        left: 0;
        bottom: 0;
        margin: 0 10px 10px;
    }

    .cookie-notice-container {
        text-align: left;
        width: 100%;
        max-width: 950px;
        margin: 0 auto;
        position: relative;
        text-align: center;
    }

    .cn-button {
        position: relative;
        color: #fafafa!important;
        background: #febe2a;
        border: 0;
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;
        text-transform: uppercase;
        padding: 12px 25px 12px;
        margin: 0 15px 0 0;
        display: inline-flex;
        transition: background .2s;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
            text-transform: uppercase;
            padding: 10px 15px;
            margin: 0 10px 0 0;
        }

        &:hover,
        &:focus {
            color: #fff !important;
            background: $brand-primary;
            opacity: 1;
        }
    }

    .cookie-logo {
        max-width: 300px;
        margin: 0 auto 30px;
        display: block;

        @include media-breakpoint-down(xs) {
            max-width: 220px;
            margin: 0 auto 25px;
            display: block;
        }
    }

    span#cn-notice-text {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        display: block;
        margin-bottom: 20px;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
        }


        a {
            color: $brand-primary;
            text-decoration: underline;
            margin-left: 2px;

            &:hover,
            &:focus {
                color: $gray-dark;
            }
        }

        br {

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

}
