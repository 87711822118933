footer,
.footer {
    position: relative;
    color: #fff;
    padding: rem(110px 0 65px);
    background: url(../Images/footer_bg.jpg);
    background-size: cover;

    @include media-breakpoint-down(xs) {
        padding: rem(70px 0 50px);
    }

    &_main {
        font-family: 'Source Sans Pro';
        font-size: rem(18px);
        display: inline-flex;
        border-top: 1px solid #5c70a8;
        width: 100%;
        padding: rem(15px 0 0);
        margin: rem(90px 0 0);

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(xs) {
            margin: rem(40px 0 0);
        }

        li {

            &:first-child {
                margin-right: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                @include media-breakpoint-down(xs) {
                    margin-bottom: rem(5px);
                }

                a {
                    padding: 0;

                    &:after {
                        content: initial;
                    }

                    @include media-breakpoint-down(xs) {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: clip;
                        max-width: 271px;
                    }

                }
            }

            &:nth-child(2) a {
                padding-left: 0;
            }

            &:last-child {

                a {
                    padding-right: 0;

                    &:after {
                        content: initial;
                    }
                }
            }

            &.active a {
                color: $brand-primary;
            }

        }

        a {
            color: rgba(255, 255, 255, 0.9);
            padding: 0 10px;
            position: relative;

            &:hover,
            &:focus {
                color: $brand-primary;
            }

            &:after {
                content: "";
                position: absolute;
                right: rem(-1px);
                top: rem(5px);
                width: rem(1px);
                height: rem(18px);
                background: rgba(255, 255, 255, 0.9);
            }


        }
    }

    &_list {
        font-size: rem(16px);
        padding: 0;
        margin: rem(0 0 0 85px);
        list-style: none;
        position: relative;

        @media screen and (max-width: 1330px) {
            margin: rem(0 0 0 64px);
        }

        @include media-breakpoint-down(lg) {
            margin: 0 0 30px auto;
            width: 175px;
            display: block;
        }

        @include media-breakpoint-down(xs) {
            margin: 0 0 25px 45px;
        }


        li {
            line-height: rem(26px);

            &:last-of-type {

                .footer_icon {
                    transform: translate(-45px,2px);
                }
            }
        }

        a {
            text-decoration: underline;
            color: #fff;

            &:hover,
            &:focus {
                color: $brand-primary;
            }
        }

        strong {
            font-size: rem(18px);
        }
    }

    &_logo {
        max-width: rem(230px);
        transform: translate(-54px,-7px);

        @media screen and (max-width: 1530px) {
            transform: translate(0,-10px);
        }

        @include media-breakpoint-down(lg) {
            transform: translate(0,-20px);
        }

        @include media-breakpoint-down(xs) {
            max-width: rem(155px);
        }
    }

    &_icon {
        transform: translate(-45px,-2px);
        position: absolute;
        left: 0;
    }


    .btn {
        margin: 0;
    }


    .col-logo {

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0;
            left: 0;
        }

        @include media-breakpoint-down(xs) {
            position: relative;
            margin-bottom: 30px;
        }
    }

    .align-end {
        margin-left: auto;
    }
}
