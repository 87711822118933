.product-card {
    background: #fff;
    padding: 30px 15px;
    margin: 0 15px 30px;
    border: 1px solid transparent;
    display: inline-flex;
    position: relative;
    flex-flow: row wrap;
    align-items: center;
    max-width: calc(100% / 4 - 35px);
    flex: 1 1 360px;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.1);
    transition: box-shadow .3s, border-color .2s;

    @include media-breakpoint-down(lg) {
        max-width: rem(360px);
        margin: 25px 10px;
    }

    @include media-breakpoint-down(sm) {
        padding: 25px 10px 10px;
        margin: 20px 10px;
    }


    &:hover,
    &:focus {
        box-shadow: 3px 3px 25px rgba(255, 255, 255, 0.1);
        border-color: rgba(22, 46, 131, 0.2);
        text-decoration: none;
    }


    p {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 25px;

        @include media-breakpoint-down(sm) {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 25px;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        height: 120px;
        object-fit: scale-down;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            max-width: 66px;
        }
    }

    h3 {
        min-height: 51px;
        object-fit: scale-down;
    }

}


