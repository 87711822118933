.modal-open {
    overflow: hidden;
    padding: 0 !important;

    main {
        animation: none;
    }
}

.modal-backdrop.show {
    opacity: .65;
}

.modal {

    .modal-dialog {
        max-width: 800px;
        transform: scale(.96) !important;
        transform-origin: center;
    }

    .modal-content {
        border: 0;
        box-shadow: 2px 3px 40px rgba(0, 0, 0, 0.35);
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        margin: auto;
        opacity: 1;
        background: $brand-primary;
        padding: 12px 20px;
        transition: background .3s;

        &:hover,
        &:focus {
            background: $brand-primary;
            opacity: 1 !important;
        }

        .icon {
            font-size: 33px;
            font-weight: 300;
            color: #fff;
            text-shadow: none;
        }
    }

    figcaption {
        position: absolute;
        color: #fff;
        padding: 7px 50px 7px 20px;
        font-size: 16px;
        font-style: italic;
        background: $brand-primary;
        background: linear-gradient(90deg, rgba(190,21,34,.9) 60%, rgba(190,21,34,0) 100%);
        bottom: 15px;
    }


    &.show .modal-dialog {
        transform: scale(1) !important;
    }

}