.no-list {
    padding: 0;
    list-style: none;
}

.list-checked {
    padding-left: 65px;
    list-style: none;
    margin: 0;

    li {
        margin-bottom: 35px;
        position: relative;

        &:before {
            content: "\f058";
            color: #00b5cc;
            font-family: Font Awesome\ 5 Pro;
            font-size: 29px;
            font-weight: 300;
            font-variant: normal;
            text-rendering: auto;
            position: absolute;
            z-index: 9;
            top: -4px;
            left: -55px;
            transition: color .2s,transform .25s;
        }
    }
}
