.img-outdent {
    transform: translate(-65px,-95px);
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}
  
.img-responsive {
    display: block;
    width: 100%;
    height: auto;

    &_md {
        display: block;
        width: 100%;
        max-width: rem(510px);
        height: auto;
    }
}
  
.img-block {
    margin: rem(0 0 25px);
}

.img-center {
    display: block;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        margin: 20px auto;
    }
}

.error-img {
    width: 100%;
    max-width: 540px;
    display: block;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        max-width: 430px;
        display: block;
        margin: 0 auto 65px;
    }

    @include media-breakpoint-down(xs) {
        max-width: 290px;
        margin: 0 auto 45px;
    }
}
