form.wpcf7-form {
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
    background: #fff;
    padding: rem(70px 50px 10px);
    box-shadow: 3px 3px 45px rgba(0, 0, 0, 0.12);

    @include media-breakpoint-down(sm) {
        padding: rem(25px 10px 5px);
    }


    input,
    textarea {
        font-size: 18px;
        border: 0;
        border-radius: 0;
        background: #eeeeee;
        resize: none;
    }

    textarea {
        max-height: 155px;
    }

    label {
        font-size: 17px;
        font-weight: 800;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 5px;

        @include media-breakpoint-down(xs) {
            font-size: rem(15px);
        }
    }


    .form-row>.col, .form-row>[class*=col-] {
        padding-right: 15px;
        padding-left: 15px;
    }

    .form-group {
        margin-bottom: 15px;
    }


    .wpcf7-submit {
        font-size: rem(16px);
        letter-spacing: 1.5px;
        font-weight: 700;
        background: #162e83 !important;
        border-radius: 2.72727rem !important;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.17);
        padding: 17px 60px;
        max-width: 360px;
        margin: 0 auto;
        display: block;

        &:hover,
        &:focus {
            background: #0c1f62 !important;
        }


        @include media-breakpoint-down(xs) {
            font-size: rem(15px);
            padding: 17px 35px;
            max-width: 100%;
        }

    }
}

.form-control {
    transition: background .2s;

    &:focus {
        color: #000;
        border: 0;
        background: #e0e0e0;
        border-color: transparent;
        outline: 0;
        box-shadow: none;
    }
}


.wpcf7-acceptance {
    float: left;
    margin-bottom: 0 !important;

    span.wpcf7-list-item {
       margin: rem(20px 0 20px -7px);
   }
}

form .wpcf7-list-item input[type="checkbox"],
input#datenschutz {
    color: black !important;
    opacity: 1;
    margin: auto;
    height: 40px;
    width: 40px;
    margin-top: -15px;

    &:before {
        content: ""!important;
        border: 1px solid #dbdbdb!important;
        cursor: pointer!important;
        background: #fff;
        display: inline-block!important;
        float: right!important;
        height: 40px!important;
        position: relative!important;
        top: 0!important;
        width: 40px!important;
    }

    &:checked:before {
        background: url(../../../Resources/Public/Images/check.svg) no-repeat #fff;
        background-size: 24px;
        background-position: center;
    }
}


.wpcf7-response-output {
    //padding: rem(20px);
    margin: rem(25px 0 40px 0) !important;
    background: #c57575;
    color: #fff;
    border: 0 !important;
    border-radius: rem(12px);

    &.wpcf7-mail-sent-ok {
        background: $brand-secondary;
        padding: 20px !important;
        margin: rem(45px 0 40px 0);
        border: 0 !important;
    }
}

.wpcf7-not-valid-tip {
    color: $brand-secondary !important;
    font-size: 16px !important;
}