html, body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #000;
    background: #fff;
    overflow-x: hidden;
    margin: 0;
    word-break: break-word;
    scroll-behavior: smooth;

    &.home {

        header {
            position: absolute;
            background: transparent;
            box-shadow: none;
        }
    }
}


.pageFadeIn {

    main,
    footer {
        animation: fadeIn 1.7s forwards;
    }
}


.container {
    position: relative;

    @include media-breakpoint-down(md) {
        max-width: 100%
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%
    }

    @include media-breakpoint-down(xs) {
        max-width: 100%
    }


    &-wide {
        width: 1500px;
    }

    &_fluid-lg {
        max-width: 1480px;

        @include media-breakpoint-down(lg) {
            width: calc(100% + 30px);
            max-width: calc(100% + 30px);
            margin: 0 -15px;
        }
    }

    &_fluid-header {

        @include media-breakpoint-down(lg) {
            width: 100%;
            max-width: 100%;
        }
    }

}

.align-center {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
}



.margin-top-lg {
    margin-top: rem(220px);

    @include media-breakpoint-down(md) {
        margin-top: rem(190px);
    }

    @include media-breakpoint-down(xs) {
        margin-top: rem(150px);
    }
}

.margin-top-sm {
    margin-top: rem(20px);

    @include media-breakpoint-down(lg) {
        margin-top: rem(30px);
    }
}

.margin-left-md {
    margin-left: 70px;
}

.padding {

    &--md {
        padding: 70px 0;
    }
}

.no-padding {
    padding: 0 !important;
}

.content-indent {
    transform: translatex(rem(10px));

    @media screen and (min-width:1460px) {
        transform: translatex(rem(-25px));
    }

    @include media-breakpoint-down(md) {
        transform: none;
    }
}


figure {
    margin: 0;
}


//text selection highlight color
::selection,
::-moz-selection {
    background: $brand-primary;
    color: #fff;
}


/* custom scrollbar */
::-webkit-scrollbar {
    width: 6px;

    @include media-breakpoint-down(sm) {
        width: 3px;
    }
}

::-webkit-scrollbar-track {
    background: $gray-light; 
}

::-webkit-scrollbar-thumb {
    background: $brand-primary; 
}

::-webkit-scrollbar-thumb:hover {
    background: $brand-primary; 
}