#portfolioSlider {
    transform: translateY(-125px);

    .carousel-inner {
        overflow: visible;
    }

    .slider-nav {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    opacity: 1;
    text-decoration: none;
    transition: opacity .3s, transform .3s;

    &:hover {
        opacity: 1;
        color: inherit;
    }

    .icon {
        font-size: 35px;
        color: #000;
        font-weight: 300;
        transition: opacity .3s, transform .3s;

        @include media-breakpoint-down(sm) {
            font-size: 21px;
        }

        @media screen and (max-width: 380px) {
            display: none;
        }
    }
}

.carousel-control-prev {
    left: -70px;

    &:hover {

        .icon {
            transform: translateX(-5px);
        }
    }
}

.carousel-control-next {
    right: -70px;

    &:hover {

        .icon {
            transform: translateX(5px);
        }
    }
}





/** PODCAST LIST **/
.owl-carousel.podcast-list {
    width: 2500px;
    transform: translateY(171px);
    padding-bottom: 230px;

    .podcast-item {
        color: #000;
        width: 350px;
        height: 450px;
        position: relative;
        display: block;

        &:hover {
            text-decoration: none;

            img {
                transform: translateY(-25px);
            }

            &:after {
                transform: translateY(-25px);
                box-shadow: 10px 1px 40px rgba(0,0,0,.5);
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: 15px;
            top: 15px;
            margin: auto;
            z-index: -1;
            width: 90%;
            height: calc(100% - 130px);
            box-shadow: 10px 0 30px rgba(0,0,0,.25);
            transition: transform .35s, box-shadow .3s;
        }

        img {
            display: block;
            width: 100%;
            margin-bottom: 40px;
            transition: transform .35s;
        }

        h3 {
            font-size: 20px;
            line-height: 30px;
            margin: 0 0 0 12px;
        }
    }

    .owl-nav.disabled {
        display: block;
        position: absolute;
        left: 41.5vw;
        top: -30px;

        .icon {
            font-size: 34px;
        }

        .owl-prev {
            margin-right: 25px;
            transition: transform .25s;

            &:hover {
                transform: translateX(-3px);
            }
        }

        .owl-next {
            transition: transform .25s;

            &:hover {
                transform: translateX(3px);
            }
        }
    }

    .owl-stage-outer {
        padding-top: 50px;
    }
}