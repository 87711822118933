h1,.h1 {
    font-size: 70px;
    line-height: 80px;
    font-weight: 900;
    margin-bottom: 40px;
    animation: scaleIn .7s ease forwards;

    @include media-breakpoint-down(md) {
        font-size: 53px;
        line-height: 60px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 39px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 25px;
    }

    
    .overline {
        margin-bottom: rem(38px);
    }
}

h2,.h2 {
    font-size: 56px;
    font-weight: 700;
    line-height: 62px;
    margin-bottom: 40px;
    color: $brand-secondary; 

    @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 38px;
        margin-bottom: 30px;
    }
}

h3,.h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 20px;
    color: $brand-secondary; 

    @include media-breakpoint-down(sm) {
        line-height: 24px;
        margin-bottom: 10px;
        font-size: 19px;
    }
}

h4, .h4 {
    font-size: 16px;
    font-weight: 600;
    color: $gray-dark;
    margin-bottom: 18px;
    text-transform: uppercase;
    letter-spacing: .3px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
    }
}

h1,.h1,
h2,.h2
h3,.h3 {
    position: relative;
}

p {
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 30px;
    color: $gray;

    &.margin-md {
        margin-bottom: 60px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 35px;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(19px);
        line-height: rem(28px);
    }

    @include media-breakpoint-down(xs) {
        font-size: rem(17px);
        line-height: rem(22px);
    }

    &.txt-block {
        margin-bottom: rem(35px);
    }

    &.txt-sm {
        margin-bottom: rem(18px);
    }

    a {
        color: #bababa;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}

.txt-small {
    font-size: 16px;
    line-height: 24px;
    margin-left: 50px;
}

blockquote {
    font-size: 24px;
    font-weight: 500;
    font-style: italic;
    padding: 20px 25px;
    margin: 0 30px 60px;
    color: $brand-primary;
    border-left: 4px solid $brand-primary;
    display: inline-block;
    text-transform: uppercase;

    p {
        margin: 0;
        line-height: 31px;
    }
}


.section-title {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid $gray-light;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-text-fill-color: #333 !important;
    background-color: #fff !important;
}


b, strong {
    font-weight: 700;
}

.font-medium {
    font-weight: 500;
}

.content-column {

    @include media-breakpoint-up(lg) {
        columns: 2;
        column-gap: 30px;
        margin-bottom: 20px;
        overflow: hidden;
    }
}

.subline {
    font-size: 20px;
    line-height: 34px;
    color: $gray;
}

.text-center {

    &_sm {

        @include media-breakpoint-down(sm) {
            text-align: left !important;
        }
    }
}


.content-column {

    @include media-breakpoint-up(md) {
        columns: 2;
        column-gap: 30px;
        margin-bottom: 20px;
        overflow: hidden;
    }
}