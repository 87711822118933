.section {
    position: relative;
    padding: 130px 0 170px;
    background: #fff;

    @include media-breakpoint-down(sm) {
        padding: rem(50px 0 60px);
    }

    .container {
        position: relative;
    }

    &--sm {
        padding: rem(30px 0 140px);

        @include media-breakpoint-down(sm) {
            padding: rem(15px 0 90px);
        }

        @include media-breakpoint-down(xs) {
            padding: rem(0 0 90px);
        }
    }


    &--highlight {
        background: $gray-light;
        padding: 120px 0 100px;

        @include media-breakpoint-down(sm) {
            padding: 70px 0 50px;
        }
    }

    &--narrow {
        padding: 28px 0 36px;

        @include media-breakpoint-down(sm) {
            padding: rem(10px 0 12px);
        }
    }

    &--content {

        h2,
        .h2 {

            @include media-breakpoint-down(sm) {
                font-size: rem(24px);
                margin-bottom: rem(15px);
            }
        }

    }

    &--home {
        background: url(../Images/home_bg.jpg) no-repeat;
        background-size: cover;
        padding: 345px 0 250px;
        color: #fff;


        @include media-breakpoint-down(sm) {
            background-size: 300%;
            background-position: 49% -0%;
            padding: 140px 0 50px;
            color: #fff;
        }

        span {
            text-align: center;
            letter-spacing: .3px;
            margin-bottom: 95px;
            display: block;

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 18px;
                margin-bottom: 40px;
            }

        }
    }
    
    
    &--header {
        background: url(../Images/contentpage_bg.jpg) no-repeat;
        background-size: cover;
        padding: 250px 0 75px;
        color: #fff;

        @include media-breakpoint-down(sm) {
            background-size: 300%;
            background-position: 49% -0%;
            padding: 140px 0 50px;
            color: #fff;
        }

        h1, .h1 {
            margin-bottom: rem(20px);
        }

        span {
            text-align: center;
            letter-spacing: .3px;
            margin-bottom: 95px;
            display: block;

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 18px;
                margin-bottom: 40px;
            }

        }
    }


    &--contact {
        background: url(../Images/contact_bg.jpg) no-repeat #f6f6f6;
        padding: 120px 0 170px;
        background-position: left bottom 60px;

        @include media-breakpoint-down(xs) {
            padding: 100px 0;
            background-position: left bottom 30px;
            background-size: contain;
        }
    }


    &--404 {

        h1 {
            font-size: 38px;
            margin-bottom: 27px;

            @include media-breakpoint-down(xs) {
                font-size: 25px;
                margin-bottom: 25px;
            }

            span {
                display: block;
                margin: 0 22px 56px 0;
                font-size: 110px;
                font-weight: 800;

                @include media-breakpoint-down(xs) {
                    margin: 0 15px 30px 0;
                    font-size: 80px;
                }
            }
        }

        .btn {
            margin: 0 auto;
            display: block;
        }
    }

} //section
