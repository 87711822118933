// Colors
$gray-dark:                 #494949;
$gray:                      #878787;
$gray-light:                #fafafa;
 
$brand-primary:             #febe2a;
$brand-secondary:           #162e83;

$text-color:            $gray-dark;

$link-color:            $text-color;
$link-hover-color:      $brand-primary;
$link-hover-decoration: underline;

$font-family-sans-serif:  'Source Sans Pro', Helvetica, Arial, sans-serif;



//convert px to rem
$rem-baseline: 22px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
    @if $separator == "comma" or $separator == "space" {
        @return append($list, null, $separator);
    }

    @if function-exists("list-separator") == true {
        @return list-separator($list);
    }

    // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
    $test-list: ();
    @each $item in $list {
        $test-list: append($test-list, $item, space);
    }

    @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
    font-size: $zoom / 22px * $rem-baseline;
}

@function rem-convert($to, $values...) {
    $result: ();
    $separator: rem-separator($values);

    @each $value in $values {
        @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
            $result: append($result, $value / 1rem * $rem-baseline, $separator);
        } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
            $result: append($result, $value / $rem-baseline * 1rem, $separator);
        } @else if type-of($value) == "list" {
            $value-separator: rem-separator($value);
            $value: rem-convert($to, $value...);
            $value: rem-separator($value, $value-separator);
            $result: append($result, $value, $separator);
        } @else {
            $result: append($result, $value, $separator);
        }
    }

    @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
    @if $rem-px-only {
        @return rem-convert(px, $values...);
    } @else {
        @return rem-convert(rem, $values...);
    }
}

@mixin rem($properties, $values...) {
    @if type-of($properties) == "map" {
        @each $property in map-keys($properties) {
            @include rem($property, map-get($properties, $property));
        }
    } @else {
        @each $property in $properties {
            @if $rem-fallback or $rem-px-only {
                #{$property}: rem-convert(px, $values...);
            }
            @if not $rem-px-only {
                #{$property}: rem-convert(rem, $values...);
            }
        }
    }
}




// Animations
@keyframes scaleIn {
    from {transform: scale(.9); opacity: 0;}    
    to {transform: scale(1); opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}


// Webfonts

/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/source-sans-pro-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
         url('../webfonts/source-sans-pro-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/source-sans-pro-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/source-sans-pro-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/source-sans-pro-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/source-sans-pro-v12-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }

  /* source-sans-pro-600 - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../webfonts/source-sans-pro-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
         url('../webfonts/source-sans-pro-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/source-sans-pro-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/source-sans-pro-v12-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/source-sans-pro-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/source-sans-pro-v12-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/lato-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url('../webfonts/lato-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/lato-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/lato-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/lato-v15-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/lato-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'),
         url('../webfonts/lato-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/lato-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v15-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/lato-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/lato-v15-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('../webfonts/lato-v15-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Lato Black'), local('Lato-Black'),
         url('../webfonts/lato-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/lato-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v15-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/lato-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/lato-v15-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
  }