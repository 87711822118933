a,
button {
    cursor: pointer;
    color: $brand-primary;
    text-decoration: none;
    transition: color .3s;

    &:hover,
    &:focus {
        color: $gray-dark;
    }
}

.btn.active.focus, .btn.active:focus, .btn.focus,
.btn:active.focus, .btn:active:focus, .btn:focus,
:focus, :focus:active, a:focus, button:focus,
button:focus:active {
    outline: none;
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $brand-primary;
    border-color: transparent;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: $brand-primary;
    border-color: $brand-primary;
}


button {
    border: none;
    padding: 0;
    background: none;
}

a[href^=tel] {
    cursor: default;
    color: inherit;
    text-decoration: none !important;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}


a[href^=mailto] {
    text-decoration: underline;
}

.btn,
.button {
    color: #fff;
    font-size: rem(16px);
    font-weight: 500;
    line-height: rem(26px);
    letter-spacing: rem(1px);
    text-transform: uppercase;
    background: $brand-primary;
    margin-bottom: rem(30px);
    padding: rem(17px 76px);
    border: 0;
    border-radius: rem(60px);
    transition: background .3s, color .2s, border-color .3s;

    &:hover,
    &:focus {
        color: #fff;
        background: #f6ae0a;
        cursor: pointer;
        text-decoration: none;
        border: 0;
    }

    &--secondary {
        background: $brand-secondary;

        &:hover,
        &:focus {
            background: #0c1f62;
        }
    }
}

.link-external {
    padding-left: rem(10px);

    &:before {
        content: "\f08e";
        font-family: Font Awesome\ 5 Pro;
        font-size: rem(15px);
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        transform: translateX(-8px);
    }
}


.link-arrow {
    position: relative;
    font-weight: 600;
    color: #000;

    &:after {
        content: "\f178";
        font-family: Font Awesome\ 5 Pro;
        font-size: 28px;
        font-weight: 300;
        font-variant: normal;
        text-rendering: auto;
        position: absolute;
        z-index: 9;
        bottom: -6px;
        right: -50px;
        transition: color .2s,transform .25s;
    }
}

.hide-link {
    display: none;
}

.no-link {
    pointer-events: none;
}
