header {
    position: absolute;
    width: 100%;
    background: transparent;
    z-index: 101;


    @include media-breakpoint-down(sm) {
        height: 90px;
        padding: 4px 0;
    }

    @include media-breakpoint-down(xs) {
        height: 70px;
        padding: 0;
    }

    .navbar-brand {
        padding: rem(15px 0);
        z-index: 101;

        @include media-breakpoint-down(md) {
            padding: rem(30px 0 20px);
        }

        @include media-breakpoint-down(sm) {
            transform: none;
            padding: rem(15px 0);
        }

        img {
            width: rem(215px);
            transition: width .3s, width .3s;

            @include media-breakpoint-down(md) {
                width: rem(190px);
            }

            @include media-breakpoint-down(sm) {
                width: rem(150px);
            }

            @include media-breakpoint-down(xs) {
                width: rem(117px);
            }
        }


        &_icon img {
            width: rem(50px);
        }
    }

    #nav-toggle {
        width: rem(38px);
        height: rem(25px);
        position: relative;
        margin: rem(10px);
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 999;

        @include media-breakpoint-down(lg) {
            margin: 0;
            top: 0;
        }

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #fff;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: rem(10px);
            }

            &:nth-child(4) {
                top: rem(20px);
            }
        }

        &.open {

            span {
                background: $brand-secondary;
            }

            span:nth-child(1) {
                top: rem(12px);
                width: 0%;
                left: 50%;
            }

            span:nth-child(2) {
                transform: rotate(45deg);
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
            }

            span:nth-child(4) {
                top: rem(18px);
                width: 0%;
                left: 50%;
            }

        }
    }

    .navbar {
        padding: 10px 0 8px;
        transition: padding .2s ease;

        @media screen and (max-width: 1380px) {
            padding: 35px 0 0;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 15px 4px;
        }

        .container {
            
            @media screen and (max-width: 1380px) {
                flex-wrap: wrap;
            }
        }
    }

    .navbar-collapse {
        position: relative;
        transform: translateY(5px);
        transition: transform .35s ease;
        z-index: 100;
        transition: max-width .5s;

        @include media-breakpoint-down(lg) {
            flex-basis: 1 !important;
        }

        @include media-breakpoint-down(lg) {
            position: absolute;
            width: calc(100% + 30px);
            top: rem(-10px);
            left: 0;
            right: 0;
            margin: 0 auto;
            margin-left: -15px;
            background: #fff;
            box-shadow: 0 25px 50px hsla(0, 0%, 0%, 0.15);
        }



        .nav-block {
            padding: rem(40px 70px 20px);
            border-top: 1px solid #e5e5e5;
            flex-wrap: wrap;

            @include media-breakpoint-down(xs) {
                padding: rem(45px 70px 20px);
            }


            .h4 {
                font-size: rem(17px);
                letter-spacing: rem(1.3px);
                color: #000;
                width: 100%;
                margin-bottom: rem(15px);
            }

            .icon {
                font-size: rem(30px);
                margin-right: rem(15px);
                color: $brand-secondary;

                &:hover,
                &:focus {
                    color: $brand-primary;
                }
            }

            .navbar-nav {
                padding: 0 0 10px;

                &:before {
                    content: initial;
                }

                .nav-item .nav-link {
                    padding: rem(0 0 7px);

                    &:after {
                        top: -3px;
                        height: 28px;
                    }
                }
            }

        }

        .navbar-nav {
            margin-left: auto;
            margin-right: 0;
            align-items: flex-start;

            @include media-breakpoint-down(lg) {
                margin: 0;
                padding: 190px 70px 60px;
                position: relative;
            }

            @include media-breakpoint-down(sm) {
                padding: rem(145px 70px 50px);
            }

            @include media-breakpoint-down(xs) {
                padding: rem(130px 70px 35px);
            }

            &:before {

                @include media-breakpoint-down(lg) {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 160px;
                    width: 100%;
                    height: 1px;
                    background: #e5e5e5;

                    @include media-breakpoint-down(sm) {
                        top: 120px;
                    }

                    @include media-breakpoint-down(xs) {
                        top: 105px;
                    }
                }
            }

            a {
                text-decoration: none;
            }

            .nav-item {
                padding: 0;

                &:hover,
                &:focus {

                    .nav-link {
                        color: $brand-primary;
                    }

                    .dropdown-toggle {

                        &:after {
                            @include media-breakpoint-up(lg) {
                                color: $brand-primary !important;
                                transform: rotate(180deg);
                                transform-origin: center;
                            }
                        }
                    }

                    .dropdown-menu {
                        @include media-breakpoint-up(lg) {
                            display: block;
                            animation: menuIn .3s ease-out forwards;
                        }

                        @keyframes menuIn {
                            from {transform: translate(15px,-20px); opacity: 0;}
                            to {transform: translate(15px,-3px); opacity: 1;}
                        }


                        &:before {
                            @include media-breakpoint-up(lg) {
                                content: "";
                                position: absolute;
                                transform: translate(-35px,-22px);
                                right: 0;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 0 14px 14px 14px;
                                border-color: transparent transparent #ffffff transparent;
                            }
                        }

                        &:after {
                            @include media-breakpoint-up(lg) {
                                content: "";
                                position: absolute;
                                left: rem(-10px);
                                top: rem(5px);
                                background: #fff;
                                box-shadow: 2px 3px 15px rgba(0,0,0,.1);
                                z-index: -1;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                &.active a {
                    color: $brand-primary;
                    text-decoration: underline;

                    @include media-breakpoint-down(lg) {
                        color: #000;
                        text-decoration: none;
                    }

                    &:after {
                        color: $brand-primary !important;
                        opacity: 1;
                    }
                }


                .nav-link {
                    font-size: 17px;
                    font-weight: 700;
                    color: #fff;
                    letter-spacing: 1.3px;
                    text-transform: uppercase;
                    transition: color .25s;
                    padding: 0 20px;
                    cursor: pointer;
                    position: relative;

                    @media screen and (max-width: 1300px) {
                        padding: 0 10px;
                    }

                    @include media-breakpoint-down(lg) {
                        transition: color .2s;
                        font-size: 20px;
                        padding: 8px 0;
                        color: #000;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 17px;
                        padding: 4px 0;
                    }

                    @include media-breakpoint-down(xs) {
                        padding: 7px 0;
                    }

                    &:hover,
                    &:focus {
                        color: $brand-primary;
                    }

                    &:after {

                        @include media-breakpoint-down(lg) {
                            content: "";
                            position: absolute;
                            left: -33px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 7px;
                            height: 36px;
                            border-radius: 57px;
                            background: #172e82;
                            opacity: 0;
                            transition: width .3s,opacity .1s;
                        }
                    }
                }

                &:last-child {
                    padding-right: 0;

                    .nav-link {
                        padding-right: 0;
                    }
                }

            } // close nav-item

        } //navbar-nav


        &.collapse.show {
            opacity: 1;

            .navbar-brand {
                position: absolute;
                left: 15px;
                top: 25px;
                height: auto;
                padding: 0;

                @include media-breakpoint-down(md) {
                    top: 35px;
                }

                @include media-breakpoint-down(sm) {
                    top: 20px;
                }
            }

        }

    } //navbar-collapse

} //header


// Dont show nav active state on homepage due to onepage scrolling on homepage
.home header .navbar-collapse .navbar-nav .nav-item {

    &.active a {
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $brand-primary;
        }

        @include media-breakpoint-down(lg) {
            color: #000;
        }
    }

    .nav-link {

        &:after {
            content: initial;
        }
    }
}