.img-txt-teaser {
    padding: 40px 45px;
    width: 100%;
    max-width: 410px;
    border-radius: 13px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #000;
    text-decoration: none;
    overflow: hidden;
    transition: background .3s, box-shadow .3s;


    &:hover {
        text-decoration: none;
        background: #fff;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.15);

        .link-arrow {
            color: $brand-primary;

            &:after {
                transform: translateX(3px);
            }
        }
    }

    img {
        width: 100%;
        max-width: 290px;
        height: auto;
        margin: 0 auto 60px;
        align-self: center;
    }
}



.block-shadow {
    padding: 60px;
    width: 100%;
    max-width: 720px;
    position: relative;
    border-radius: 13px;
    background: #fff;
    box-shadow: 0 0 45px rgba(0,0,0,.15);

    .h3, h3 {
        font-size: 34px;
        line-height: 72px;
        margin-bottom: 25px;
        letter-spacing: .3px;
    }

    .btn, .btn-ghost {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 320px;
        transform: translateY(140px);
    }
}